import { AuthenticationService } from '../services/core/authentication.service';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

/* Esta guarda se encarga de verificar que el usuario esté logueado, de lo contrario rechaza
  la ruta y obliga al usuario a ir a la pantalla de login
***/
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  check(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      await this.authService.init();
      this.authService.isAuthenticated
        .pipe(
          filter((val) => val !== null), // Filter out initial Behaviour subject value
          take(1), // Otherwise the Observable doesn't complete!
          map((isAuthenticated) => {
            if (isAuthenticated) {
              resolve(true);
            } else {
              this.router.navigateByUrl('/login');
              resolve(false);
            }
          })
        )
        .subscribe();
    });
  }

  canActivate(): Promise<boolean> {
    return this.check();
  }

  canLoad(): Promise<boolean> {
    return this.check();
  }
}
