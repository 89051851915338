import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertHandler } from 'src/app/classes/AlertHandler';
import { ApiCoreService } from 'src/app/services/core/api-core.service';
import { AuthenticationService } from 'src/app/services/core/authentication.service';
import { ApiOrdersService } from 'src/app/services/orders/api-orders.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.scss'],
})
export class EditOrderComponent implements OnInit {

  orderId: number = 0;
  isLoaded: Boolean = false;
  editOrderForm!: FormGroup;
  orderData: any;
  orderDataInicial: any;
  orderInnerData: any;
  shippingStatesList!: Array<any>;
  shippingLocalitiesList!: Array<any>;
  trackingStatusList!: Array<any>;
  trueVal: boolean = true;
  shippingServiceLevelsList!: Array<any>;
  productsListControl!: FormArray<any>;

  trackingStatusIsEnabled: boolean = true;
  serviceLevelIsEnabled: boolean = true;
  productListIsEnabled: boolean = true;
  isFirstPage: boolean = false;
  isLastPage: boolean = false;
  inventoryLinks: any;
  inventoryList: any;
  isSuperuser: boolean = false;

  filteredTrackingStatusList!: Array<any>;

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private alertHandler: AlertHandler,
    private apiCore: ApiCoreService,
    private apiOrders: ApiOrdersService,
    private readonly fb: FormBuilder,
    private router: Router
  ) { }

  addProduct() {
    const pL = this.editOrderForm.get('ProductsList') as FormArray;
    pL.push(this.createProductFormGroup('', ''));
  }

  addProductFromList(sku: string, qty: number) {
    const pL = this.editOrderForm.get('ProductsList') as FormArray;
    if (environment.debug) { console.log(pL.value); }
    const productAlreadyInList = pL?.value?.some((x: any) => x.sku === sku);
    if (productAlreadyInList) { // producto ya se encuentra en la lista
      this.alertHandler.showAlert('Acción no permitida', '', 'Producto ya se encuentra en la lista');
    } else {
      pL.push(this.createProductFormGroup(sku, qty + ''));
    }
  }

  backToList() {
    this.router.navigateByUrl('/app/orders/dashboard-orders', { replaceUrl: true });
  }

  checkLinks() {
    this.isFirstPage = (this.inventoryLinks.self == (this.inventoryLinks.first ?? ''));
    this.isLastPage = (this.inventoryLinks.self == (this.inventoryLinks.last ?? ''));
  }

  controlNum(index: number): void {
    const quantityValue = Number((<HTMLInputElement>document.getElementById(`quantity${index}`)).value);
    const skuValue = (<HTMLInputElement>document.getElementById(`sku${index}`)).value;

    if (Number.isNaN(Number(quantityValue + ''))) {
      //(<HTMLInputElement>document.getElementById(`quantity${index}`)).value = "0";
      let pL: FormArray = <FormArray>this.editOrderForm.get('ProductsList');
      let item = pL.at(index);
      item.setValue({
        sku: skuValue,
        quantity: 0 + ''
      });
    }

    let qtyAvailableArr = this.inventoryList.find((x: any) => x.sku === skuValue);
    let qtyAvailable = (qtyAvailableArr?.qty_available) ?? 0;

    if (quantityValue > qtyAvailable) {
      //(<HTMLInputElement>document.getElementById(`quantity${index}`)).value = qtyAvailable.toString();
      let pL: FormArray = <FormArray>this.editOrderForm.get('ProductsList');
      let item = pL.at(index);
      item.setValue({
        sku: skuValue,
        quantity: this.getQtyAvailable(skuValue) + ''
      });
    }
  }

  private createProductFormGroup(skuVal = '', qtyVal = ''): FormGroup {
    return this.fb.group({
      sku: [skuVal, [Validators.required]],
      quantity: [qtyVal, [Validators.required, Validators.max(this.getQtyAvailable(skuVal))]]
    })
  }

  deleteProduct(index: number) {
    const pL = this.editOrderForm.get('ProductsList') as FormArray;
    pL.removeAt(index);
  }

  filterStatusOptions() {

    const input = this.editOrderForm.value.StatusFilterInput;
    if (input === '') this.filteredTrackingStatusList = this.trackingStatusList;
    else this.filteredTrackingStatusList = this.trackingStatusList.filter(x => {
      return x.label.toUpperCase().includes(input.toUpperCase())
    });
    if (this.filteredTrackingStatusList.length === 1) {
      this.editOrderForm.patchValue({
        TrackingStatus: this.filteredTrackingStatusList[0].tracking_status
      });
    }
  }

  async getOptionLists() {
    if (this.isSuperuser) {
      const statusList: any = await this.apiOrders.trackingStatusEditableList(this.orderId);
      this.trackingStatusList = statusList.rows;
    }

    const levelsList: any = await this.apiCore.serviceLevelsList(this.orderInnerData.attributes.service_id);
    this.shippingServiceLevelsList = levelsList;
    const statesList: any = await this.apiCore.statesList('CL');
    this.shippingStatesList = statesList.data;
    const localitiesList: any = await this.apiCore.localitiesList('CL', this.orderData?.attributes?.shipping_state);
    this.shippingLocalitiesList = localitiesList.data;
  }

  getMaxLength(controlName: string) {
    return this.editOrderForm.get(controlName)?.errors?.['maxlength']['requiredLength'];
  }

  getQtyAvailable(sku: string) {
    let qtyAvailableArr = this.inventoryList.find((x: any) => x.sku === sku);
    let qtyAvailable = (qtyAvailableArr?.qty_available) ?? 0;
    return qtyAvailable;
  }

  private initForm(): FormGroup {
    let productsListElem: any = [null, []]; // LM productsList es vacío
    if (this.orderData?.attributes?.products_list?.length) {
      productsListElem = this.fb.array(this.orderData?.attributes?.products_list.map(
        (elem: any) => {
          return this.fb.group({
            sku: [elem.sku, [Validators.required]],
            quantity: [elem.quantity, [Validators.required, Validators.min(1), Validators.max(this.getQtyAvailable(elem.sku))]]
          })
        }
      ))
    }

    return this.fb.group({
      TrackingStatus: [this.orderData?.attributes?.tracking_status, [Validators.required, Validators.maxLength(30)]],
      ShippingServiceLevel: [this.orderData?.attributes?.shipping_service_level, [Validators.required, Validators.maxLength(30)]],
      ShippingAddress1: [this.orderData?.attributes?.shipping_address1, [Validators.required, Validators.maxLength(100)]],
      ShippingAddress2: [this.orderData?.attributes?.shipping_address2, [Validators.required, Validators.maxLength(100)]],
      ShippingAddress3: [this.orderData?.attributes?.shipping_address3, [Validators.required, Validators.maxLength(100)]],
      ShippingEmail: [this.orderData?.attributes?.shipping_email, [Validators.required, Validators.maxLength(100)]],
      ShippingPhone: [this.orderData?.attributes?.shipping_phone, [Validators.required, Validators.maxLength(15)]],
      ShippingState: [this.orderData?.attributes?.shipping_state, [Validators.required]], //Pais soportado: CL
      ShippingLocality: [this.orderData?.attributes?.shipping_locality, [Validators.required]],
      StatusFilterInput: ['', []],
      ProductsList: productsListElem
    });
  }

  async getProductsList() {
    try {
      const res: any = await this.apiOrders.inventoryListForClient(this.orderInnerData.attributes.client_id);
      this.inventoryList = res.rows;
      this.inventoryLinks = res.links;

      this.checkLinks();
      if (environment.debug) {
        console.log(res);
      }

      this.isLoaded = true;

    } catch (err) {
      if (environment.debug)
        console.log(err);
    }
  }

  async stateChanged(event: any) {
    const state = (event.target as HTMLInputElement).value;
    try {
      const rLocalities: any = await this.apiCore.localitiesList('CL', state);
      this.shippingLocalitiesList = rLocalities.data;
      this.shippingLocalitiesList.sort((a: any, b: any) => {
        if (a.state_descr > b.state_descr) return 1;
        if (a.state_descr < b.state_descr) return -1;
        return 0;
      });
    } catch (err) {
      this.shippingLocalitiesList = [];
    }
  }

  async ngOnInit() {

    const user = await this.authService.getUserData();
    this.isSuperuser = (user.clientId == null);

    this.orderId = this.route.snapshot.params['order_id'];
    this.orderData = await this.apiOrders.ffOrderDetailForEdition(this.orderId + '');
    this.orderDataInicial = { ...this.orderData };

    this.orderInnerData = await this.apiOrders.ffOrderDetail(this.orderId + '');

    await this.getProductsList();

    await this.getOptionLists();

    this.editOrderForm = this.initForm();

    this.productsListControl = this.editOrderForm.get('ProductsList') as FormArray;

    this.filterStatusOptions();

    this.isLoaded = true;

    console.log("Order ID: ", this.orderId);
    console.log("Order DATA: ", this.orderData);
    console.log("Order DATA inicial: ", this.orderDataInicial);
    console.log("Order innerData: ", this.orderInnerData);
    console.log("Edit order Form: ", this.editOrderForm);
    console.log("Product List Control: ", this.productsListControl);
  }

  async orderChangesBeforeSubmit(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const orderDataBeforeSubmit: any = await this.apiOrders.ffOrderDetailForEdition(this.orderId + '');
      let diferencias: any = {};

      for (const attr in this.orderDataInicial.attributes) {
        if (attr === 'products_list') continue;
        if (attr === 'num_lines') continue;
        else if (this.orderDataInicial.attributes[attr] !== orderDataBeforeSubmit.attributes[attr]) {
          diferencias[attr] = [this.orderDataInicial.attributes[attr], orderDataBeforeSubmit.attributes[attr]];
        }
      }
      let diferenciasMsg = Object.entries(diferencias).map((x: any) => x[0] + ': ' + x[1][1]).join(', ');
      if (environment.debug) { console.log({ diferencias, diferenciasMsg }); }

      return resolve(diferenciasMsg);
    });
  }

  async nextPage() {
    try {
      const res: any = await this.apiOrders.inventoryListForClient(this.inventoryLinks.next ?? '');
      this.inventoryList = res.rows;
      this.inventoryLinks = res.links;
      this.checkLinks();
    } catch (err) {
      if (environment.debug)
        console.error(err);
    }
  }

  async previousPage() {
    try {
      const res: any = await this.apiOrders.inventoryListForClient(this.inventoryLinks.prev ?? '');
      this.inventoryList = res.rows;
      this.inventoryLinks = res.links;
      this.checkLinks();
    } catch (err) {
      if (environment.debug)
        console.error(err);
    }
  }

  async submit() {
    this.editOrderForm.disable();
    if (!this.editOrderForm.invalid) {
      let changesBeforeSubmit = await this.orderChangesBeforeSubmit();
      if (changesBeforeSubmit.length > 0) {
        this.alertHandler.showAlert('No se pudo realizar la operación por cambio', '', 'Se actualizará formulario con datos nuevos: ' + changesBeforeSubmit);
        await this.ngOnInit();
      } else {
        if (!this.editOrderForm.invalid) {
          const finalOrderForm = this.editOrderForm.value;
          const finalOrder: any = { attributes: {} };
          finalOrder.attributes.order_id = Number(this.orderId);
          if (finalOrderForm.ProductsList) finalOrder.attributes.num_lines = finalOrderForm.ProductsList?.length;
          else finalOrder.attributes.num_lines = null;
          finalOrder.attributes.total_value = null;
          finalOrder.attributes.tracking_status = finalOrderForm.TrackingStatus;
          finalOrder.attributes.shipping_service_level = finalOrderForm.ShippingServiceLevel;
          finalOrder.attributes.shipping_address1 = finalOrderForm.ShippingAddress1;
          finalOrder.attributes.shipping_address2 = finalOrderForm.ShippingAddress2;
          finalOrder.attributes.shipping_address3 = finalOrderForm.ShippingAddress3;
          finalOrder.attributes.shipping_email = finalOrderForm.ShippingEmail;
          finalOrder.attributes.shipping_phone = finalOrderForm.ShippingPhone;
          finalOrder.attributes.shipping_state = finalOrderForm.ShippingState;
          finalOrder.attributes.shipping_locality = finalOrderForm.ShippingLocality;
          finalOrder.attributes.products_list = finalOrderForm.ProductsList;
          let isError = false;
          this.alertHandler.showLoadingMessage('Por favor, espera.');
          const result: any = await this.apiOrders.ffOrderEdit(finalOrder, this.orderId).catch(err => {
            if (environment.debug) { console.log({ err }); };
            isError = true;
            this.alertHandler.dismissLoadingMessage();
            this.alertHandler.showAlert('No se pudo editar orden', '', err.error.error);
          });
          if (environment.debug) { console.log({ result }); };
          this.alertHandler.dismissLoadingMessage();
          if (result) {
            this.alertHandler.showAlert('Orden editada', '', result.mensaje);
            this.router.navigateByUrl('/app/orders/details-orders/' + this.orderId, { replaceUrl: true });
          } else {
            if (!isError) this.alertHandler.showAlert('Error no identificado', '', 'result no definido');
          }
        } else {
          this.alertHandler.showAlert('Formulario con errores', '', 'Por favor, verifica que los datos estén completos e intenta de nuevo');
        }
      }
    } else {
      this.alertHandler.showAlert('Formulario inválido', '', 'Por favor, verifica que los datos estén completos e intenta de nuevo');
    }
    this.editOrderForm.enable();
  }
}
