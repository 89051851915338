import { ApplicationInitializerFactory } from './translation.config';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './components/components.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { LayoutComponent } from './pages/layout/layout.component';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';

import { TranslateModule, TranslateService, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditOrderModule } from './pages/orders/edit-order/edit-order.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent
  ],
  imports: [
    EditOrderModule,
    AppRoutingModule,
    BrowserModule,
    ComponentsModule,
    CommonModule,
    HttpClientModule,
    IonicModule.forRoot(),
    TranslateModule.forRoot({ // <--- add this
      loader: { // <--- add this
        provide: TranslateLoader, // <--- add this
        useFactory: (createTranslateLoader),  // <--- add this
        deps: [HttpClient] // <--- add this
      } // <--- add this
    }),
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [ TranslateService, Injector ],
      multi: true
    },
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ] ,
  bootstrap: [AppComponent],
})
export class AppModule {}
