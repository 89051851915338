<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute fixed-top">
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <div class="navbar-brand">{{getTitle()}}</div>
        </div>
        <button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
            <span class="sr-only">Toggle navigation</span>
            <!-- <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span> -->
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navigation">
            <!-- <form class="navbar-form">
                <div class="input-group no-border">
                    <input type="text" value="" class="form-control" placeholder="Search...">
                    <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                        <i class="material-icons">search</i>
                        <div class="ripple-container"></div>
                    </button>
                </div>
            </form> -->
            <ul class="navbar-nav">
                <!-- <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)">
                        <p>
                            <span class="d-lg-none d-md-block">Stats</span>
                        </p>
                    </a>
                </li> -->
                <li class="nav-item dropdown">
                    <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons">person</i> ¡Hola, <span *ngIf="this.user.userName !== undefined || this.user.userName !== null"> {{this.user.userName}} </span> <span *ngIf="this.user.userName === undefined || this.user.userName === null"> Usuario </span>!
                        <!-- <span class="notification">5</span> -->
                        <p>
                            <span class="d-lg-none d-md-block">Some Actions</span>
                        </p>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <!-- <a class="dropdown-item" href="/app/admin-clients">Clientes</a>
                        <a class="dropdown-item" href="/app/create-client">Configuracion</a> -->
                        <!-- <a class="dropdown-item" href="/login">Salir</a> -->
                        <a class="dropdown-item" href="mailto:soporte@next4.cl"> Contacta a Soporte </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
