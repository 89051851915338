<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-around">
      <div class="container editContainer">
        <div class="card">
          <div class="card-header card-header-danger">
            <h4 class="card-title">
              <span *ngIf="isLoaded">
                <a class="nav-link" (click)="backToList()">
                  <i class="material-icons" title="Volver">arrow_back</i>
                </a>
                Edición de orden {{ orderId }}
              </span>
            </h4>
          </div>
          <div *ngIf="isLoaded" class="card-body">
            <form (ngSubmit)="submit()" [formGroup]="editOrderForm" *ngIf="editOrderForm">
              <div class="col-12">
                <div class="containerForm bg-white p-3 col-12">
                  <div class="row">
                    <div class="col">
                      <label class="label-black" for="ShippingState">Región de destino</label>
                      <select class="form-control text-black" name="ShippingState" value=""
                        aria-label="Selecciona una región..." formControlName="ShippingState"
                        (change)="stateChanged($event)">
                        <option *ngFor="let state of shippingStatesList" [value]="state.state_code">
                          {{ state.state_name }}
                        </option>
                      </select>
                    </div>
                    <div class="col">
                      <label class="label-black" for="ShippingLocality">Comuna de destino</label>
                      <select class="form-control text-black" name="ShippingLocality" value=""
                        aria-label="Selecciona una región..." formControlName="ShippingLocality">
                        <option *ngFor="let locality of shippingLocalitiesList" [value]="locality.locality_name">
                          {{ locality.locality_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <label class="label-black" for="ShippingAddress1">Dirección 1</label>
                      <input type="text" class="form-control text-black" id="ShippingAddress1" name="ShippingAddress1"
                        formControlName="ShippingAddress1" placeholder="Dirección 1..." />
                      <div
                        *ngIf="(this.editOrderForm.get('ShippingAddress1')?.touched) && this.editOrderForm.get('ShippingAddress1')?.errors?.['maxlength']"
                        style="color: red">
                        *supera la longitud máxima permitida ({{ getMaxLength('ShippingAddress1') }})
                      </div>
                    </div>
                    <div class="col">
                      <label class="label-black" for="ShippingAddress2">Dirección 2</label>
                      <input type="text" class="form-control text-black" id="ShippingAddress2" name="ShippingAddress2"
                        formControlName="ShippingAddress2" placeholder="Dirección 2..." />
                      <div
                        *ngIf="(this.editOrderForm.get('ShippingAddress2')?.touched) && this.editOrderForm.get('ShippingAddress2')?.errors?.['maxlength']"
                        style="color: red">
                        *supera la longitud máxima permitida ({{ getMaxLength('ShippingAddress2') }})
                      </div>
                    </div>
                    <div class="col">
                      <label class="label-black" for="ShippingAddress3">Dirección 3</label>
                      <input type="text" class="form-control text-black" id="ShippingAddress3" name="ShippingAddress3"
                        formControlName="ShippingAddress3" placeholder="Dirección 3..." />
                      <div
                        *ngIf="(this.editOrderForm.get('ShippingAddress3')?.touched) && this.editOrderForm.get('ShippingAddress3')?.errors?.['maxlength']"
                        style="color: red">
                        *supera la longitud máxima permitida ({{ getMaxLength('ShippingAddress3') }})
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col">
                      <label class="label-black" for="ShippingEmail">Correo electronico</label>
                      <input type="text" class="form-control text-black" id="ShippingEmail" name="ShippingEmail"
                        formControlName="ShippingEmail" placeholder="nombre@mail.com..." />
                      <div
                        *ngIf="(this.editOrderForm.get('ShippingEmail')?.touched) && this.editOrderForm.get('ShippingEmail')?.errors?.['maxlength']"
                        style="color: red">
                        *supera la longitud máxima permitida ({{ getMaxLength('ShippingEmail') }})
                      </div>
                    </div>
                    <div class="col">
                      <label class="label-black" for="ShippingPhone">Numero de telefono</label>
                      <input type="text" class="form-control text-black" id="ShippingPhone" name="ShippingPhone"
                        formControlName="ShippingPhone" placeholder="+56 9 1234 5678..." />
                      <div
                        *ngIf="(this.editOrderForm.get('ShippingPhone')?.touched) && this.editOrderForm.get('ShippingPhone')?.errors?.['maxlength']"
                        style="color: red">
                        *supera la longitud máxima permitida ({{ getMaxLength('ShippingPhone') }})
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div class="row">
                    <div class="col">
                      <div class="col">
                        <label class="label-black" for="TrackingStatus">Estado de la orden: </label>
                        <input *ngIf="!isSuperuser || !trackingStatusIsEnabled" class="form-control" type="text"
                          disabled value="{{orderData.attributes.tracking_status}}" />
                        <div *ngIf="isSuperuser && trackingStatusIsEnabled" class="row">
                          <div class="col col-3">
                            <input type="text" class="form-control text-black" formControlName="StatusFilterInput"
                              (input)="filterStatusOptions()" placeholder="Filtrar lista de estados...">
                          </div>
                        </div>
                        <select *ngIf="isSuperuser && trackingStatusIsEnabled" class="form-control text-black"
                          name="TrackingStatus" value="{{orderData.attributes.tracking_status}}"
                          aria-label="Selecciona un estado" formControlName="TrackingStatus">
                          <option *ngFor="let x of filteredTrackingStatusList" [value]="x.tracking_status">
                            {{ x.label }}
                          </option>
                        </select>
                        <div
                          *ngIf="(this.editOrderForm.get('TrackingStatus')?.touched) && this.editOrderForm.get('TrackingStatus')?.errors?.['maxlength']"
                          style="color: red">
                          *supera la longitud máxima permitida ({{ getMaxLength('TrackingStatus') }})
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col">
                      <div class="col">
                        <label class="label-black" for="ShippingServiceLevel">Nivel de servicio de la orden</label>
                        <input *ngIf="!serviceLevelIsEnabled" class="form-control text-black" type="text" disabled
                          value="{{orderData.attributes.shipping_service_level}}" />
                        <select *ngIf="serviceLevelIsEnabled" class="form-control text-black"
                          name="ShippingServiceLevel" value="" aria-label="Selecciona un nivel de servicio"
                          formControlName="ShippingServiceLevel">
                          <option *ngFor="let level of shippingServiceLevelsList" [value]="level.sl_code">
                            {{ level.sl_description }}
                          </option>
                        </select>
                        <div
                          *ngIf="(this.editOrderForm.get('ShippingServiceLevel')?.touched) && this.editOrderForm.get('ShippingServiceLevel')?.errors?.['maxlength']"
                          style="color: red">
                          *supera la longitud máxima permitida ({{ getMaxLength('ShippingServiceLevel') }})
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <ng-container *ngIf="productListIsEnabled && productsListControl?.controls">
                    <label class="label-black">Lista de productos</label>
                    <div class="row">
                      <div class="col col-1">
                        <label class="label-black">#</label>
                      </div>
                      <div class="col">
                        <label class="label-black">SKU</label>
                      </div>
                      <div class="col">
                        <label class="label-black">Cantidad</label>
                      </div>
                      <div class="col col-1">
                        <label class="label-black"></label>
                      </div>
                    </div>
                    <div formArrayName="ProductsList">
                      <div *ngFor="let item of productsListControl?.controls, index as i">
                        <div [formGroupName]="i">
                          <div class="row">
                            <div class="col col-1"><label class="label-black">{{i+1}}</label></div>
                            <div class="col">
                              <input [readonly]="true" type="text" class="form-control" id="sku{{i}}" name="sku{{i}}"
                                formControlName="sku" placeholder="SKU..." />
                            </div>
                            <div class="col">
                              <input type="text" [min]="1" [max]="10000" class="form-control text-black"
                                id="quantity{{i}}" name="quantity{{i}}" formControlName="quantity"
                                placeholder="Cantidad..." (input)="controlNum(i)" />
                            </div>
                            <!-- <div *ngIf="(this.editOrderForm?.get('ProductsList')?.[i]?.get('quantity')?.touched) && this.editOrderForm?.get('ProductsList')?.[i]?.get('quantity')?.errors?.['max']" style="color: red">
                                *cantidad debe ser un entero positivo con un máximo permitido de 1000
                              </div> -->
                            <div class="col col-1">
                              <div class="row">
                                <ion-button id="editOrderDeleteProduct{{i}}Button" (click)="deleteProduct(i)"
                                  expand="block" size="small">
                                  <ion-icon name="trash-outline"></ion-icon>
                                </ion-button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col">
                        <ion-buttons>
                          <ion-button id="editOrderAddProductButton" (click)="addProduct()" slot="end">
                            <ion-icon slot="start" name="add"></ion-icon>
                            Agregar producto
                          </ion-button>
                        </ion-buttons>
                      </div>
                    </div> -->
                    <div id="catalogo" class="card-body">
                      <div class="table-responsive">
                        <table class="table table-hover text-center">
                          <thead class="text-dark fw-bolder">
                            <th> # </th>
                            <th> SKU </th>
                            <th> Nombre </th>
                            <th> Unidades disponibles </th>
                            <th> Acciones </th>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="let item of inventoryList; index as id">
                              <tr>
                                <td>
                                  {{id+1}}
                                </td>
                                <td>
                                  {{item.sku}}
                                </td>
                                <td>
                                  {{item.product_name}}
                                </td>
                                <td>
                                  {{item.qty_available}}
                                </td>
                                <td>
                                  <ion-button (click)="addProductFromList(item.sku,1)">
                                    <ion-icon slot="start" name="add"></ion-icon> Agregar
                                  </ion-button>
                                </td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                      <div class="row justify-content-center">
                        <ion-button size="small" color="secondary" class="col col-lg-2 col-md-2 col-sm-2"
                          (click)="previousPage()" disabled="{{ isFirstPage }}">
                          <i class="material-icons">skip_previous</i>
                          Anterior
                        </ion-button>
                        <div class="col col-lg-1 col-md-1 col-sm-1">&nbsp;</div>
                        <ion-button size="small" color="secondary" class="col col-lg-2 col-md-2 col-sm-2"
                          (click)="nextPage()" disabled="{{ isLastPage }}">
                          Siguiente
                          <i class="material-icons">skip_next</i>
                        </ion-button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button id="editOrderGuardarButton" class="btn btn-danger">Guardar</button>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="!isLoaded" class="card-body">
            <p>Cargando...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>