import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { EditOrderComponent } from './edit-order.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrintOrderLabelModule } from '../print-order-label/print-order-label.module';

@NgModule({
  declarations: [
    EditOrderComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    PrintOrderLabelModule
  ]
})
export class EditOrderModule { }
