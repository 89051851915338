import { ApiNoauthService } from './api-noauth.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { environment } from 'src/environments/environment';

const TOKEN_KEY = "next4-token";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isAuthenticated: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  token='';

  constructor(
    private api: ApiNoauthService
  ) {
  }

  async getToken() {
    if (this.token=="") {
      await this.validateToken();
    }
    return this.token;
  }

  async getUserData() {
    const { value } = await Preferences.get({ key: TOKEN_KEY });
    if (value) {
      const data = JSON.parse(value);
      return data;
    } else {
      return null;
    }
  }

  async init() {
    await this.validateToken();
  }

  async login(credentials: {email: string,password:string}) {
    try {
      const data = {
        username: credentials.email,
        password: credentials.password
      }
      const r = await this.api.userLogin(data);
      const token = (r.data?.AuthenticationResult?.IdToken ?? '');
      const refreshToken = (r.data?.AuthenticationResult?.RefreshToken ?? '');
      const expiresIn = (Date.now()+1000*(r.data?.AuthenticationResult.ExpiresIn ?? 0));
      if (token!="") {
        const data = {
          token: token,
          refreshToken: refreshToken,
          expiresIn: expiresIn,
          clientId: r.data?.client_id,
          userRole: r.data?.user_role,
          userName: r.data?.user_name,
          userRut: r.data?.user_rut,
          userPhone: r.data?.user_phone
        };
        const sData = JSON.stringify(data);
        const rs = await Preferences.set({ key: TOKEN_KEY, value: sData });
        this.token = token;
        this.isAuthenticated.next(true);
        return { loggedIn: true, isClient: data.clientId ?? false, isSuperuser: data.clientId == null};
      } else {
        return { loggedIn: false, message: 'Credenciales inválidas' };
      }
    } catch (err:any) {
      this.isAuthenticated.next(false);
      return { loggedIn: false, message: err.message };
    }
  }

  async logout() {
    this.isAuthenticated.next(false);
    this.token="";
    await Preferences.remove({ key: TOKEN_KEY });
  }

  async validateToken() {
    try {
      const { value } = await Preferences.get({ key: TOKEN_KEY });
      if (value) {
        const data = JSON.parse(value);
        const expiresIn = data.expiresIn;
        if (expiresIn && expiresIn < Date.now()) {
          const r = await this.api.refreshToken(data.refreshToken);
          const expiresIn = (Date.now()+1000*(r.AuthenticationResult.ExpiresIn ?? 0));
          const newData = {
            token: r.AuthenticationResult.IdToken,
            refreshToken: data.refreshToken,
            expiresIn: expiresIn,
            clientId: r.data?.client_id,
            userRole: r.data?.user_role,
            userName: r.data?.user_name,
            userRut: r.data?.user_rut,
            userPhone: r.data?.user_phone
          };
          const sData = JSON.stringify(newData);
          const rs = await Preferences.set({ key: TOKEN_KEY, value: sData });
          this.token = r.AuthenticationResult.IdToken;
        } else {
          this.token = data.token;
        }
        this.isAuthenticated.next(true);
      } else {
        if (environment.debug) {
          console.log('Local storage get failed');
        }
        this.isAuthenticated.next(false);
      }
    } catch (err) {
      if (environment.debug) {
        console.log(err);
      }
      this.isAuthenticated.next(false);
    }
  }
}
