import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertHandler{

  loading: any;

  constructor(private alertController: AlertController, public loadingController: LoadingController) {
  }

  async showAlert(hdr:any, shdr:any, msg:any){
    const alert = this.alertController.create({
      cssClass: 'my-custom-modal-css',
      header: hdr,
      subHeader: shdr,
      message: msg,
      buttons: ['OK']
    });

    return (await alert).present();
  }

  async showLoadingMessage(msg:any){
    const message = msg ? msg : 'Por favor, espere...';
    this.loading = this.loadingController.create({
      message: message
    });

    (await this.loading).present();
  }

  async dismissLoadingMessage(){
    if(this.loading){
      (await this.loading).dismiss();
      this.loading = undefined;
    }
  };
}
