import { AuthenticationService } from '../core/authentication.service';
import { CommonService } from '../core/common.service';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiOrdersService {
  constructor(
    private auth: AuthenticationService,
    private common: CommonService,
    public http: HttpClient
  ) {}

  async ffOrderClientKpi(startDate: string, endDate: string) {
    try {
      const url: string = this.common.makeDataURL(
        'orders',
        'ff-order/client-kpi/',
        startDate+"/"+endDate
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async ffOrderClientGroupKpi(startDate: string, endDate: string) {
    try {
      const url: string = this.common.makeDataURL(
        'orders',
        'ff-order/client-group-kpi/',
        startDate+"/"+endDate
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async ffOrderGetClientStatusGroupKpi(startDate: string, endDate: string, statusGroup: string, page: number) {
    try {
      const url: string = this.common.makeDataURL(
        'orders',
        'ff-order/get-client-status-group-kpi/',
        startDate+"/"+endDate+"/"+statusGroup+"/"+page
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async ffOrderDetail(orderId: string = '0') {
    try {
      const url: string = this.common.makeDataURL(
        'orders',
        'ff-order/detail/',
        orderId.toString()
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async ffOrderDetailForEdition(orderId: string = '0') {
    try {
      const url: string = this.common.makeDataURL(
        'orders',
        'ff-order/detail-edit/',
        orderId.toString()
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async ffOrderEdit(orderObj: any, orderId: number) {
    try {
      const url: string = this.common.makeDataURL(
        'orders',
        'ff-order/edit/',
        orderId+''
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const pdata = JSON.stringify(orderObj);
      console.log(pdata);
      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async ffOrderGetZpl(orderList: any){
    try {
      const url: string = this.common.makeDataURL(
        'orders',
        'ff-order/get-zpl',
        ''
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const pdata = JSON.stringify({testMode: false, orderList: orderList});

      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async ffOrderGetList(idList: string){
    try {
      const url: string = this.common.makeDataURL(
        'orders',
        'ff-order/order-list/',
        idList
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };

      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async ffOrdersList(clientId: number, orderBy: string, serviceFilter: string, statusFilter: string, startDate: string, endDate: string, page: number) {
    try {
      var url;
      url = this.common.makeDataURL(
        'orders',
        'ff-order/list/',
        clientId+"/"+orderBy+"/"+serviceFilter+"/"+statusFilter+"/"+startDate+"/"+endDate+"/"+page
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async ffOrdersListPagination(link: string) {
    try {
      var url;
      url = this.common.makeDataURL(
        'orders',
        '',
        link
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async ffOrderUpdateFromWmsStatus(orderId: Number) {
    try{
      let url = this.common.makeDataURL(
        'orders',
        'ff-order/update-from-wms-status/',
        orderId+''
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const pdata = {};

      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async inventoryList(fixedUrl=''){
    try {
      var url;
      if (fixedUrl=='')
        url = this.common.makeDataURL(
          'orders',
          'inventory/list/',
          '0'
        );
      else
        url = this.common.makeDataURL(
          'orders',
          fixedUrl,
          ''
        );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async inventoryListForClient(clientId: number, fixedUrl=''){
    try {
      var url;
      if (fixedUrl=='')
        url = this.common.makeDataURL(
          'orders',
          'inventory/listForClient/'+clientId+'/',
          '0'
        );
      else
        url = this.common.makeDataURL(
          'orders',
          fixedUrl,
          ''
        );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async trackingStatus() {
    try {
      const url: string = this.common.makeDataURL(
        'orders',
        'config/tracking-status',
        ''
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async trackingStatusEditableList(orderId: number) {
    try {
      const url: string = this.common.makeDataURL(
        'orders',
        'config/tracking-status-editable/',
        orderId+''
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async uploadASN(asn: any) {
    try {
      const url: string = this.common.makeDataURL('orders', 'asn', '');
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const pdata = JSON.stringify(asn);
      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async uploadOrder(order: any) {
    try {
      const url: string = this.common.makeDataURL('orders', 'ff-order/create-batch', '');
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const pdata = JSON.stringify(order);
      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }
}
