import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  sessionChange = 0;
  private sessionChangeSubject = new Subject<number>();

  constructor() {}

  changeSession() {
    this.sessionChange++;
    this.sessionChangeSubject.next(this.sessionChange);
  }

  getSessionChangeObservable() {
    return this.sessionChangeSubject.asObservable();
  }
}
