<div class="logo">
  <a class="simple-text" href="/app/orders/dashboard-orders">
    <div>
      <img src="./assets/img/logo-next4.png">
    </div>
  </a>
</div>
<div class="sidebar-wrapper ps">
  <ul class="nav" *ngIf="isLoaded">
    <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
      <a class="nav-link" [routerLink]="[menuItem.path]" *ngIf="menuItem.isActivate === true" [id]="[menuItem.id]">
          <i class="material-icons">{{menuItem.icon}}</i>
          <p class="fw-bolder">{{menuItem.title}}</p>
      </a>
    </li>
    <li class="nav-item" *ngIf="isSuperuser">
      <button class="bg-white text-settings m-settings-10"  (click)="settings()" id="settingsButton">
        <i class="material-icons">settings</i>
        <p class="fw-bolder">Configuracion</p>
      </button>
    </li>
    <li routerLinkActive="active" *ngFor="let subMenuItem of subMenuItems" class="{{subMenuItem.class}} nav-item">
      <a class="nav-link" [routerLink]="[subMenuItem.path]" *ngIf="subMenuItem.isActivate === true" [id]="[subMenuItem.id]">
          <i class="material-icons">{{subMenuItem.icon}}</i>
          <p class="fw-bolder">{{subMenuItem.title}}</p>
      </a>
    </li>
    <li class="nav-item">
      <button class="bg-white text-logout m-logout-10"  (click)="logout()" id="logoutButton">
        <i class="material-icons">logout</i>
        <p class="fw-bolder">Cerrar sesión</p>
      </button>
    </li>
  </ul>
</div>
