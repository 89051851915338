import { CommonService } from './common.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiNoauthService {

  constructor(
    private common: CommonService,
    public http: HttpClient
  ) { }

  confirmChangePassword(credentials: any): Promise<any> {
    const url: string = this.common.makeDataURL('core', 'user/confirm-change-password', '');
    const pdata = JSON.stringify(credentials);
    return firstValueFrom(this.http.post(url, pdata));
  }

  refreshToken(token: any): Promise<any> {
    const url: string = this.common.makeDataURL('core','user/refresh-token','');
    const pdata = JSON.stringify({ refreshToken: token });
    return firstValueFrom(this.http.post(url,pdata));
  }

  requestCode(email: string): Promise<any> {
    const url: string = this.common.makeDataURL('core', 'user/request-change-password', '');
    const pdata = JSON.stringify({ email: email });
    return firstValueFrom(this.http.post(url, pdata));
  }

	userLogin(credentials: any): Promise<any> {
    const url: string = this.common.makeDataURL('core', 'user/login', '');
    const pdata = JSON.stringify(credentials);
    return firstValueFrom(this.http.post(url, pdata));
  }
}
