import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/core/authentication.service';
import { Subscription } from 'rxjs';
import { SessionService } from 'src/app/services/core/session.service';
import { Router } from '@angular/router';
import { ApiCoreService } from 'src/app/services/core/api-core.service';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    img: string;
    class: string;
    id: string;
    isActivate: boolean,
};

export const ROUTES: RouteInfo[] = [
    { path: '/app/dashboard', title: 'Dashboard',  img: 'null', icon: 'dashboard', class: '', id:'DashboardSidebar', isActivate: true},
    { path: '/app/orders/dashboard-orders', title: 'Distribución',  img: 'smartpickup-blanco.png', icon: 'directions_car', class: '', id:'ordersDashboardSidebar', isActivate: true},
    { path: '/app/fulfillment/dashboard-fulfillment', title: 'Fulfillment',  img:'picktac-blanco.png', icon: 'store', class: '', id:'fulfillmentDashboardSidebar', isActivate: true},
];

export const SUBROUTES: RouteInfo[] = [
  { path: '/app/admin-clients', title: 'Clientes',  img: 'null', icon: 'admin_panel_settings', class: 'subMenuClass', id:'clientsDashboardSidebar', isActivate: false},
  { path: '/app/provider/admin-provider', title: 'Proveedores',  img:'', icon: 'group', class: 'subMenuClass', id:'providersDashboardSidebar', isActivate: false},
  { path: '/app/warehouse/admin-warehouse', title: 'Bodegas',  img:'', icon: 'warehouse', class: 'subMenuClass', id:'warehousesDashboardSidebar', isActivate: false},
  { path: '/app/courier/admin-courier', title: 'Couriers',  img:'', icon: 'local_shipping', class: 'subMenuClass', id:'couriersDashboardSidebar', isActivate: false},
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  menuItems: any = [];
  subMenuItems: any = [];
  public isLoaded: boolean = false;
  public isLoggedIn: boolean = false;
  public isClient: boolean = false;
  public isSuperuser: boolean = false;
  private serviceId: string = '';
  private sessionChangeSubscription: Subscription | undefined;

  constructor(
    private apiCoreService: ApiCoreService,
    private authService: AuthenticationService,
    private sessionService: SessionService,
    private router: Router
  ) {
  }

  async getUser() {
    this.menuItems = ROUTES;
    this.subMenuItems = SUBROUTES;
    const user = await this.authService.getUserData();
    if (user) {
      const userInfo:any = await this.apiCoreService.userGet();
      this.isClient=(user.clientId!=null);
      this.isSuperuser=(user.clientId==null);
      this.serviceId = userInfo.client_service_id;

      this.menuItems[0].isActivate = (this.isSuperuser==true);
      this.menuItems[2].isActivate = (this.isSuperuser==true || this.serviceId=='FF');
      this.isLoggedIn = true;
      this.isLoaded = true;
    } else if (this.isLoggedIn) {
      await this.logout();
      this.isLoggedIn = false;
    }
  };

  ngOnDestroy() {
    this.sessionChangeSubscription?.unsubscribe();
  }

  async ngOnInit() {
    await this.getUser();
    /* La suscripción a sessionService permite que el método "getUser", que se encarga de referescar
    las opciones del menú lateral, sea llamado cada vez que hay un nuevo login
    ****/
    this.sessionChangeSubscription = this.sessionService
      .getSessionChangeObservable()
      .subscribe(async () => {
        await this.getUser();
      });
  }

  async logout() {
    await this.authService.logout();
    this.sessionService.changeSession();
    this.router.navigateByUrl('/login',{replaceUrl: true});
  }

  settings(){
    if (this.subMenuItems[0].isActivate && this.subMenuItems[1].isActivate){
      this.subMenuItems[0].isActivate = false;
      this.subMenuItems[1].isActivate = false;
      this.subMenuItems[2].isActivate = false;
      this.subMenuItems[3].isActivate = false;
    }
    else{
      this.subMenuItems[0].isActivate = (this.isSuperuser==true);
      this.subMenuItems[1].isActivate = (this.isSuperuser==true);
      this.subMenuItems[2].isActivate = (this.isSuperuser==true);
      this.subMenuItems[3].isActivate = (this.isSuperuser==true);
    }
  }
}
