import { AuthenticationService } from './authentication.service';
import { CommonService } from './common.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiCoreService {
  constructor(
    private auth: AuthenticationService,
    private common: CommonService,
    public http: HttpClient
  ) {}

  async catalogSave(catalogList: any) {
    try {
      const url: string = this.common.makeDataURL('core', 'catalog/save', '');
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const pdata = JSON.stringify(catalogList);
      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async catalogSaveBatch(catalogList: any) {
    try {
      const url: string = this.common.makeDataURL('core', 'catalog/save-batch', '');
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const pdata = JSON.stringify(catalogList);
      if (environment.debug)
        console.log(pdata);
      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientDeactivate(data: any) {
    try {
      const url: string = this.common.makeDataURL('core', 'client/deactivate', '');
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };

      const pdata = JSON.stringify(data);
      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientGet(clientId:any) {
    try {
      const url: string = this.common.makeDataURL('core', 'client/details/', clientId);
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientLastMileDelete(data: any) {
    try {
      const url: string = this.common.makeDataURL('core', 'client/last-mile-delete', '');
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const pdata = JSON.stringify(data);
      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientLastMileGet(clientId:any,unitId:any,courierShortName:any) {
    try {
      const url: string = this.common.makeDataURL('core', 'client/last-mile/', clientId+"/"+unitId+"/"+courierShortName);
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientLastMileSave(data: any) {
    try {
      const url: string = this.common.makeDataURL('core', 'client/last-mile-save', '');
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const pdata = JSON.stringify(data);
      if (environment.debug)
        console.log(pdata);
      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientSave(data: any) {
    try {
      const url: string = this.common.makeDataURL('core', 'client/save', '');
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const pdata = JSON.stringify(data);
      if (environment.debug)
        console.log(pdata);
      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientsList(fixedUrl='') {
    try {
      let url;
      if (fixedUrl=='')
        url = this.common.makeDataURL('core', 'client/list', '');
      else
        url = this.common.makeDataURL('core', fixedUrl, '');
      const token = await this.auth.getToken();
      const options = {
        headers: {
          Authorization: token,
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientStoreDelete(data: any) {
    try {
      const url: string = this.common.makeDataURL('core', 'client/store-delete', '');
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const pdata = JSON.stringify(data);
      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientStoreGet(clientId:any,unitId:any,storeId:any) {
    try {
      const url: string = this.common.makeDataURL('core', 'client/store/', clientId+"/"+unitId+"/"+storeId);
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientStoreSave(data: any) {
    try {
      const url: string = this.common.makeDataURL('core', 'client/store-save', '');
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const pdata = JSON.stringify(data);
      if (environment.debug)
        console.log(pdata);
      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientUnitDeactivate(data: any) {
    try {
      const url: string = this.common.makeDataURL('core', 'client/unit-deactivate', '');
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const pdata = JSON.stringify(data);
      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientUnitGet(clientId:any,unitId:any) {
    try {
      const url: string = this.common.makeDataURL('core', 'client/unit/', clientId+"/"+unitId);
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientUnitSave(data: any) {
    try {
      const url: string = this.common.makeDataURL('core', 'client/unit-save', '');
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const pdata = JSON.stringify(data);
      if (environment.debug)
        console.log(pdata);
      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientFulfillmentDelete(data: any) {
    try {
      const url: string = this.common.makeDataURL('core', 'client/fulfillment-delete', '');
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const pdata = JSON.stringify(data);
      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientWarehouseGet(clientId:any,unitId:any,warehouseId:any) {
    try {
      const url: string = this.common.makeDataURL('core', 'client/fulfillment/', clientId+"/"+unitId+"/"+warehouseId);
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientWarehouseSave(data: any) {
    try {
      const url: string = this.common.makeDataURL('core', 'client/fulfillment-save', '');
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const pdata = JSON.stringify(data);
      if (environment.debug)
        console.log(pdata);
      const r = await firstValueFrom(this.http.post(url, pdata, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async countriesList() {
    try {
      const url: string = this.common.makeDataURL(
        'core',
        'config/countries',
        ''
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async couriersList(country: string) {
    try {
      const url: string = this.common.makeDataURL(
        'core',
        'config/couriers/',
        country
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async fulfillersList(country:string) {
    try {
      const url: string = this.common.makeDataURL(
        'core',
        'config/fulfillers/',
        country
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async idTypesList(country: string) {
    try {
      const url: string = this.common.makeDataURL(
        'core',
        'config/id-types/',
        country
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async statusGroupList() {
    try {
      const url: string = this.common.makeDataURL(
        'core',
        'config/status-groups/',
        ''
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async clientsListAll() {
    try {
      const url: string = this.common.makeDataURL(
        'core',
        'client/list-all',
        ''
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async providers() {
    try {
      const url: string = this.common.makeDataURL(
        'core',
        'config/providers/',
        ''
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async providersList(country: string) {
    try {
      const url: string = this.common.makeDataURL(
        'core',
        'config/providers/',
        country
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async servicesList() {
    try {
      const url: string = this.common.makeDataURL(
        'core',
        'config/services/',
        ''
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async serviceLevelsList(service_id: string){
    try {
      const url: string = this.common.makeDataURL(
        'core',
        'config/service-levels/',
        service_id
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async statesList(country: string) {
    try {
      const url: string = this.common.makeDataURL(
        'core',
        'config/states/',
        country
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async localitiesList(country: string, state: string) {
    try {
      const url: string = this.common.makeDataURL(
        'core',
        'config/localities/',
        country+'/'+state
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async userGet() {
    try {
      const url: string = this.common.makeDataURL('core', 'user/service-id', '');
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async warehousesList(id_provider: any) {
    try {
      const url: string = this.common.makeDataURL(
        'core',
        'config/warehouses/',
        id_provider
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }

  async ffServiceLevelsList() {
    try {
      const url: string = this.common.makeDataURL(
        'core',
        'config/ff-service-levels/',
        ''
      );
      const options = {
        headers: {
          Authorization: await this.auth.getToken(),
        },
      };
      const r = await firstValueFrom(this.http.get(url, options));
      return r;
    } catch (err) {
      throw err;
    }
  }
}
