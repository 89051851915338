<div class="wrapper">
  <div class="sidebar" data-color="danger" data-background-color="white">
    <app-sidebar></app-sidebar>
    <div class="sidebar-background" style="background-image: url(./assets/img/sidebar.jpg)"></div>
  </div>
  <div class="main-panel">
      <app-navbar></app-navbar>
      <router-outlet></router-outlet>
      <div *ngIf="isMaps('maps')">
          <app-footer></app-footer>
      </div>
  </div>
</div>
