import { Component, OnInit, Sanitizer, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertHandler } from 'src/app/classes/AlertHandler';
import { ApiCoreService } from 'src/app/services/core/api-core.service';
import { ApiOrdersService } from 'src/app/services/orders/api-orders.service';

@Component({
  selector: 'app-print-order-label',
  templateUrl: './print-order-label.component.html',
  styleUrls: ['./print-order-label.component.scss'],
})
export class PrintOrderLabelComponent implements OnInit {

  isLoaded: Boolean = false;
  ordersList : any = [];
  encodedPdf: any = null;
  serviceList: any = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private alertHandler: AlertHandler,
    private apiOrders: ApiOrdersService,
    private apiCore: ApiCoreService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {

  }

  async ngOnInit() {
    const rs: any = await this.apiCore.ffServiceLevelsList();
    this.serviceList = rs.data;
    const r:any = await this.apiOrders.ffOrderGetList(this.activatedRoute.snapshot.paramMap.get('orders_list') ?? '');
    this.ordersList = r.data.rows;
    // Crear un objeto para mapear códigos de servicio a descripciones
    const serviceMap: any = {};
    // Llenar el objeto con el mapeo usando this.serviceList
    for (const service of this.serviceList) {
      serviceMap[service.sl_code] = service.sl_description;
    };
    for (let order = 0; order < this.ordersList.length; order++) {
      this.ordersList[order].order_created = this.fixDate(this.ordersList[order].order_created);
      const orderItem = this.ordersList[order];
      const serviceCode = orderItem.shipping_service_level;
      // Verificar si hay una descripción correspondiente en el objeto de mapeo
      if (serviceMap.hasOwnProperty(serviceCode)) {
        orderItem.shipping_service_level = serviceMap[serviceCode];
      };
    };
    await this.printOrder(this.ordersList);
    this.isLoaded = true;
  }

  async printOrder(orderList:any){
    return new Promise(async (resolve, reject)=>{
      let buffer:any = await this.apiOrders.ffOrderGetZpl(orderList).catch((err:any)=>{
        this.alertHandler.showAlert('Hubo un error!', 'No puede imprimir mas de 50 etiquetas (incluyendo bultos) a la vez', 'Error: ' + err.name + '. Status_code: ' + err.status);
        this.isLoaded = true;
        return reject(err);
      });
      const hexArray = new Uint8Array(buffer.data.map((x:any) => parseInt(x, 10)));
      var blob = new Blob([hexArray], {
        type: 'application/pdf'
      });
      var objectURL = URL.createObjectURL(blob);

      //this.encodedPdf = this.sanitizer.sanitize(SecurityContext.HTML, objectURL);
      this.encodedPdf = this.sanitizer.bypassSecurityTrustResourceUrl(objectURL);

      return resolve(objectURL);
    });
  }

  backToDashboard() {
    this.router.navigateByUrl('/app/orders/dashboard-orders', { replaceUrl: true });
  }

  fixDate (date: any) {
    let res = date.split('T')
    res[1] = res[1].split('.')
    let fixRes = res[0];
    return fixRes.replaceAll('-','/');
  }

}
