<div class="main-content">
  <div class="container-fluid">
    <div class="row justify-content-around">
      <div class="container editContainer">
        <div class="card sample-iframe">
          <div class="card-header card-header-danger">
            <h4 class="card-title">
              <span *ngIf="isLoaded">
                <a class="nav-link" (click)="backToDashboard()">
                  <i class="material-icons" title="Volver">arrow_back</i>
                </a>
                Etiquetas de órdenes: <span *ngFor="let id of ordersList"> {{ id.order_id }} || </span>
              </span>
            </h4>
          </div>
          <div *ngIf="isLoaded" class="card-body sample-iframe">
            <iframe id="orders-frame" *ngIf="encodedPdf" class="sample-iframe" [src]="encodedPdf" frameborder="0"></iframe>
          </div>
          <div *ngIf="!isLoaded" class="card-body">
            <p>Cargando...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
