<footer class="footer">
  <div class="container-fluid">
      <nav class="pull-left">
          <ul>
              <li>
                  <a href="https://next4.cl">
                      Next4 | TOBEN SPA
                  </a>
              </li>
          </ul>
      </nav>
      <div class="copyright pull-right">
          &copy;
          {{test | date: 'yyyy'}}
      </div>
  </div>
</footer>
