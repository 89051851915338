import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PrintOrderLabelComponent } from './print-order-label.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    PrintOrderLabelComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class PrintOrderLabelModule { }
