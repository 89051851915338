import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class CommonService {
	public EndPointsDev = [
		{type: 'core', url: 'https://r4mfm4ayw8.execute-api.us-east-1.amazonaws.com/dev/'},
		{type: 'orders', url: 'https://7qfalp37f3.execute-api.us-east-1.amazonaws.com/dev/'}
	];
	public EndPointsTest = [
		{type: 'core', url: 'https://r4mfm4ayw8.execute-api.us-east-1.amazonaws.com/dev/'},
		{type: 'orders', url: 'https://7qfalp37f3.execute-api.us-east-1.amazonaws.com/dev/'}
	];
	public EndPointsProd = [
		{type: 'core', url: 'https://8e8pw1g66h.execute-api.sa-east-1.amazonaws.com/prod/'},
    	{type: 'orders', url: 'https://evtezdkg84.execute-api.sa-east-1.amazonaws.com/prod/'}
	];

	constructor() {
	}

	/**
	 * Generates the full url required for an api call
	 * @method makeDataURL
	 * @command  {string} specific transaction
	 * @data {string} additional data in url
	 * @return {Promise}
	 */
	public makeDataURL(type: string, command: string, data: string): string {
		var endpoint;

		if (environment.stage=='prod')
			endpoint = this.EndPointsProd.filter(item => item.type === type);
		else if (environment.stage=='test')
			endpoint = this.EndPointsTest.filter(item => item.type === type);
		else
			endpoint = this.EndPointsDev.filter(item => item.type === type);
		let uri = endpoint[0].url + command;
		uri += data;
		return uri;
	}
}

